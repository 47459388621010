import * as Sentry from '@sentry/react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import mixpanel from 'mixpanel-browser';
import { env } from './env';
import { getClientProvider } from './helpers/db';
import './i18n';

mixpanel.init(env.mixpanel.token);

const mixpanelUserId = mixpanel.get_property('$user_id');
const mixpanelDeviceId = mixpanel.get_property('$device_id');
console.log(`mixpanel $user_id: ${mixpanelUserId}`);
console.log(`mixpanel $device_id: ${mixpanelDeviceId}`);

const isLocalEnv = env.envName === 'local';
if (!isLocalEnv) {
  Sentry.init({
    dsn: env.sentry.dsn,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    // This sets the sample rate at 10%. You may want to change it to 100% while
    // in development and then sample at a lower rate in production.
    replaysSessionSampleRate: 0.1,
    // If you're not already sampling the entire session, change the sample rate
    // to 100% when sampling sessions where errors occur.
    replaysOnErrorSampleRate: 1.0,
    release: env.getSentryRelease(),
  });
  Sentry.setUser({ $user_id: mixpanelUserId, $device_id: mixpanelDeviceId });
}

export async function main() {
  if (process.env.REACT_APP_TARGET === 'member') {
    import('./apps/member');
  } else if (process.env.REACT_APP_TARGET === 'admin') {
    import('./apps/admin');
  } else {
    throw new Error('Target not supported: ' + process.env.REACT_APP_TARGET);
  }

  // WORKAROUND: START - Need this to resolve gapi load error
  getClientProvider();
  onAuthStateChanged(getAuth(), (user) => {
    console.log('auth changed', user);
    if (user) {
      mixpanel.identify(user.uid);
      mixpanel.people.set({
        $id: user.uid,
        $email: user.email,
        $name: user.displayName,
        $created: user.metadata.creationTime,
      });
      if (!isLocalEnv) {
        Sentry.setUser({
          id: user.uid,
          email: user.email ?? undefined,
          name: user.displayName,
          $user_id: user.uid,
          $device_id: mixpanel.get_property('$device_id'),
        });
      }
    }
  });
  // WORKAROUND: END
}

main();

export const env = {
  envName: process.env.REACT_APP_ENV_NAME || 'local',
  target: process.env.REACT_APP_TARGET || 'unknown',
  builtAt: process.env.REACT_APP_BUILT_AT || 'unknown',
  mixpanel: {
    token: process.env.REACT_APP_MIXPANEL_TOKEN || '',
  },
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN || '',
  },
  getSentryRelease: () => {
    return `${env.target}@${env.envName}@${env.builtAt}`;
  },
};

console.log(`App: ${env.getSentryRelease()}`);

import type { ClientProvider } from '@reto/shared/lib/providers/client-provider';
import { BrowserClientProvider } from '@reto/shared/lib/providers/browser-client-provider';
import { getApp } from 'firebase/app';
import 'firebase/firestore';

import '../firebase';

export function getClientProvider(): ClientProvider {
  return new BrowserClientProvider({
    app: getApp(),
  });
}

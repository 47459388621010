import i18n, { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './localizations/en/translation.json';
import ja from './localizations/ja/translation.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: en,
  },
  ja: {
    translation: ja,
  },
};

use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'ja',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
